import $ from 'jquery';
import Cookies from 'js-cookie';

import * as ScrollMagic from 'scrollmagic'; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from 'gsap'; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

$(document).ready(function () {
	function sendRequest(f) {
		var id = f.getAttribute('id');
		var form = $('#' + id);
		var action = 'form_' + form.attr('data-form-action');
		var data = form.serialize() + '&request_action=' + action;
		var button = form.find('button[type="submit"]');
		var msg = 'Twoja wiadomość została wysłana';

		$.ajax({
			url: '/request.php',
			data: data,
			type: 'POST',
			async: true,
			beforeSend: function () {
				button.addClass('button--loading');
			},
			success: function () {
				// console.log('success');
				form.find('.msg-confirm').remove();
				form.append('<div class="msg-confirm"></div>');
				form.find('.msg-confirm')
					.html('<i class="ic ic--correct"></i>')
					.append(
						'<p class="text-center">' +
							msg +
							'</p><p class="text-center"><button class="button btn-clean-msg">ok</button></p>'
					)
					.hide()
					.fadeIn(500, function () {
						$('.msg-confirm');
					});
				form.find('.form-content').attr('style', 'display:none');
				button.removeClass('button--loading');

				//reset form
				$('.btn-clean-msg').bind('click', function () {
					form.find('input, textarea').val('');
					form.find('.form-content').css('display', 'block');
					form.find('.msg-confirm').remove();
				});
			},
			always: function () {},
			done: function (e) {
				var resp = JSON.parse(e);
				if (resp.code == 422 || resp.code == 500) {
					console.log('error');
				} else {
					console.log('correct');
				}
				button.removeClass('btn-loading');
			},
			fail: function (e, o) {
				alert('Wystąpił błąd w połączeniu');
			},
		});

		return false;
	}

	var App = {
		//init Function
		init: function init() {
			App.cookies();
			App.footerYear();
			App.fullPageMainpage();
			App.fullPageProtectionSubpage();
			App.hideHeader();
			App.otherStuff();
			App.removeOrphans();
			App.sendFormDamages();
		},

		//footer year
		cookies: function cookies() {
			// cookies init

			var lang = $('.lang li.lang__item--active a').text();
			var cookies_text = '';

			if (lang == 'pl') {
				cookies_text =
					'Używamy informacji zapisanych za pomocą plików cookies w celu zapewnienia maksymalnej wygody w korzystaniu z naszego serwisu. Jeżeli się zgadzasz, zatwierdź komunikat, jeśli nie wyrażasz zgody, ustawienia dotyczące plików cookies możesz zmienić w swojej przeglądarce.';
			} else {
				cookies_text =
					'We use information saved using cookies to ensure maximum convenience in using our website. If you agree, approve the message, if you do not agree, you can change the settings for cookies in your browser.';
			}

			var cookies =
				'<div class="cookies-wrap">\
                      <div class="cookies">\
                        <div class="cookies__desc">\
                          <i class="ic ic-cookies"></i>\
                          <p>' +
				cookies_text +
				'</p>\
                        </div>\
                        <div class="cookies__button">\
                          <button id="cookies-agree" class="hollow button">ok</button>\
                        </div>\
                      </div>\
                      <button title="Zamknij" class="button button--icon button-close"><i class="fa fa-times" aria-hidden="true"></i></button>\
                    </div>';

			if (Cookies.get('name') == undefined) {
				// console.log(Cookies.get('name'))

				$(cookies).appendTo('body');

				$('.cookies-wrap .button-close').bind('click', function () {
					$('.cookies-wrap').fadeOut();
				});

				$('#cookies-agree').bind('click', function () {
					Cookies.set('Findia', 'true');

					$('.cookies-wrap').fadeOut();
				});
			}

			if (Cookies.get('Findia') == 'true') {
				$('.cookies-wrap').css('display', 'none');
			}
		},

		//footer year
		footerYear: function footerYear() {
			var dateNow = new Date();
			var fullYear = dateNow.getFullYear();
			$('#year').html(fullYear);
		},

		//full page init & destroy
		fullPageMainpage: function fullPageMainpage() {
			var fullPageCreated = false;

			createFullpage();

			function createFullpage() {
				if (fullPageCreated === false) {
					fullPageCreated = true;
					$('.fullpage').fullpage({
						navigation: true,
						responsiveWidth: 1241,
						sectionSelector: '.panel',
					});
				}
			}

			// check if there is class
			var intro = $('.intro');

			if (intro.length == true) {
				if (document.documentElement.clientWidth <= 1241) {
					$.fn.fullpage.destroy('all');
				}

				$(window).resize(function () {
					//moblie width change 400
					if ($(window).width() > 1240) {
						createFullpage();
					} else {
						if (fullPageCreated == true) {
							fullPageCreated = false;
							$.fn.fullpage.destroy('all');
						}
					}
				});
			}
		},

		//full page init & destroy
		fullPageProtectionSubpage: function fullPageProtectionSubpage() {
			var fullPageCreated = false;

			createFullpageProtection();

			function createFullpageProtection() {
				if (fullPageCreated === false) {
					fullPageCreated = true;

					$('#protectionCyber').fullpage({
						// sectionsColor: ['#ff73a1', '#4BBFC3', '#7BAABE', 'whitesmoke', '#ccddff', '#ccc'],
						// anchors: ['firstPage', 'secondPage', '3rdPage'],
						// menu: '#menu',
						controlArrows: false,
						slidesNavigation: true,
						scrollHorizontally: true,
						sectionSelector: '.panel',
						scrollHorizontally: true,
						//for http://demo.bloomnet.pl
						// scrollHorizontallyKey: 'ZGVtby5ibG9vbW5ldC5wbF9DVThjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDU2Q1c=',
						//for findia.pl
						scrollHorizontallyKey:
							'ZmluZGlhLnBsX0dPM2MyTnliMnhzU0c5eWFYcHZiblJoYkd4NVFueg==',
						afterSlideLoad: function (
							anchorLink,
							index,
							slideAnchor,
							slideIndex
						) {
							// console.log("slideLoad--" + "anchorLink: " + anchorLink + " index: " + index + " slideAnchor: " + slideAnchor + " slideIndex: " + slideIndex);
						},

						onSlideLeave: function (
							anchorLink,
							index,
							slideIndex,
							direction,
							nextSlideIndex
						) {
							// console.log("----------------");
							// console.log(" index: " + index + " slideIndex: " + slideIndex + " direction: " + direction);

							// console.log(nextSlideIndex)

							var active = 'chart-image__item--active';

							if (nextSlideIndex == 0) {
								$('.chart')
									.removeClass('step-2 step-3')
									.addClass('step-1');

								$('.chart-image li').removeClass(active);
								$('.chart-image > li:nth-child(1)').addClass(
									active
								);
							} else if (nextSlideIndex == 1) {
								$('.chart')
									.removeClass('step-1 step-3')
									.addClass('step-2');

								$('.chart-image li').removeClass(active);
								$('.chart-image > li:nth-child(2)').addClass(
									active
								);
							} else if (nextSlideIndex == 2) {
								$('.chart')
									.removeClass('step-1 step-2')
									.addClass('step-3');

								$('.chart-image li').removeClass(active);
								$('.chart-image > li:nth-child(3)').addClass(
									active
								);
							}
						},
					});
				}
			}

			// check if there is id
			var intro = $('#protectionCyber');

			if (intro.length == true) {
				if (document.documentElement.clientWidth <= 1241) {
					$.fn.fullpage.destroy('all');
				}

				$(window).resize(function () {
					//moblie width change 400
					if ($(window).width() > 1240) {
						createFullpageProtection();
					} else {
						if (fullPageCreated == true) {
							fullPageCreated = false;
							$.fn.fullpage.destroy('all');
						}
					}
				});
			}
		},

		// hide Header on on scroll down
		hideHeader: function hideHeader() {
			var headerId = $('header.header');
			var didScroll;
			var lastScrollTop = 0;
			var delta = 5;
			var navbarHeight = headerId.outerHeight();

			$(window).scroll(function (event) {
				didScroll = true;
			});

			setInterval(function () {
				if (didScroll) {
					hasScrolled();
					didScroll = false;
				}
			}, 0);

			function hasScrolled() {
				var st = $(this).scrollTop();

				if (Math.abs(lastScrollTop - st) <= delta) return;

				if (st > lastScrollTop && st > navbarHeight) {
					//scroll down
					headerId.removeClass('nav-down').addClass('nav-up');
				} else if (st < 5) {
					headerId.removeClass('nav-down');
				} else {
					//scroll up
					if (st + $(window).height() < $(document).height()) {
						headerId.removeClass('nav-up').addClass('nav-down');
					}
				}

				lastScrollTop = st;
			}
		},

		//other plugins
		otherStuff: function otherStuff() {
			//open link in new tab
			$('a[href^="http://bloomnet.eu"], .list_partners a, .fb').attr(
				'target',
				'_blank'
			);

			$('.list-accordion__link').bind('click', function (e) {
				e.preventDefault();

				var item = $(this).parents('.list-accordion__item');

				item.find('.list-accordion__desc').slideToggle(200);
				item.toggleClass('list-accordion__item--active');
			});

			$('.skip-link').on('click', function (e) {
				var name = $(this).attr('href');

				$('html, body').animate(
					{
						scrollTop: $('' + name + '').offset().top - 100,
					},
					250
				);

				if ((name = '#zglos-szkode')) {
					$('#damage-name-company').focus();
				}

				e.preventDefault();
			});

			// $('.sticky').stick_in_parent();

			//clone heading section: Activities after incident
			var cloneHeading = $('.stages__item--action .process h6').clone();
			cloneHeading.appendTo('.stages__item--action .row');

			//init counter
			$('.counter').counterUp({
				time: 1000,
				formatter: function (n) {
					return n.replace(/,/g, ' ');
				},
			});

			//clone elements for mobile
			// - contact
			var cloneContact = $('.contact > a').clone(),
				wrapCloneContact = $(
					'<li class="menu__item menu__item--contact" />'
				);

			wrapCloneContact.appendTo('.menu');
			cloneContact.appendTo('.menu__item--contact');

			// - lang & fb
			var cloneLang = $('.lang').clone(),
				wrapCloneLang = $('<div class="menu__footer" />'),
				cloneFb = $('.socialmedia a').clone();

			wrapCloneLang.appendTo('.top-bar-right');
			cloneFb.appendTo('.menu__footer');
			cloneLang.appendTo('.menu__footer');

			// - lloyd
			var cloneLloyd = $('.intro .img-lloyds').clone();

			cloneLloyd.appendTo('.intro-lloyd');

			// init ScrollMagic for 'protection cyber'
			var initChart = $('#initChart').length;

			if (initChart === 1) {
				var controllerTop = new ScrollMagic.Controller({
					globalSceneOptions: { triggerHook: 'onEnter' },
				});

				var showListImage = function () {
					function explode() {
						$('.chart-image')
							.animate(
								{
									left: 0,
									opacity: 1,
								},
								150
							)
							.fadeIn(150);

						$('.step-1-content').addClass('step-1-content--active');
					}

					setTimeout(explode, 700);
				};

				var hideSvgTwist = function () {
					$('.svg-twist').fadeOut(150);
				};

				var $chart = document.getElementById('chart');

				var chartAnimation = new TimelineMax()
					.add(
						TweenMax.to($chart, 1, {
							opacity: 1,
							delay: 0.25,
							onComplete: showListImage,
						})
					)
					.add(
						TweenMax.to($chart, 0.3, {
							left: 0,
							delay: 0.7,
							rotation: '+=360',
							onComplete: hideSvgTwist,
						})
					);

				var scene = new ScrollMagic.Scene({
					triggerElement: '#initChart',
					duration: 0,
					offset: 10,
					reverse: false,
				})
					.setTween(chartAnimation)
					// .addIndicators({name: '1 (duration: 600)'})
					.addTo(controllerTop);
			}

			//check resize
			updateContainer();

			$(window).resize(function () {
				updateContainer();
			});

			function updateContainer() {
				var $windowWidth = $(window).width();

				if ($windowWidth <= 480) {
					$('.top-bar .menu-icon').bind('click', function () {
						if ($('.top-bar-right').is(':visible')) {
							$('body').css('overflow', 'visible');
						} else {
							$('body').css('overflow', 'hidden');
						}
					});
				}
			}
		},

		sendFormDamages: function sendFormDamages() {
			var msg_valid_default_required,
				msg_valid_nip_required,
				msg_valid_nip_remote,
				msg_valid_phone,
				msg_valid_email_required,
				msg_valid_email_email,
				msg_valid_phone,
				msg_valid_email_or_phone,
				msg_valid_damage_description;

			if ($('html').attr('lang') == 'pl') {
				msg_valid_default_required = 'Te pole jest obowiązkowe';
				msg_valid_nip_required = 'Proszę podać NIP';
				msg_valid_nip_remote = 'Proszę podać poprawny NIP';
				msg_valid_email_required = 'Proszę wpisać e-mail';
				msg_valid_email_email = 'Proszę wpisać prawidłowy e-mail';
				msg_valid_phone = 'Proszę wpisać poprawny numer telefonu';
				msg_valid_email_or_phone =
					'Proszę wpisać prawidłowy email lub numer telefonu';
				msg_valid_damage_description = 'Proszę dodać opis szkody';
			} else {
				msg_valid_default_required = 'This field is required';
				msg_valid_nip_required = 'Please enter your NIP number';
				msg_valid_nip_remote = 'Please enter a valid Tax ID';
				msg_valid_email_required = 'Please enter an email';
				msg_valid_email_email = 'Please enter a valid email';
				msg_valid_phone = 'Please enter a valid phone number';
				msg_valid_email_or_phone =
					'Please eneter a valid email or phone number';
				msg_valid_damage_description =
					'Please enter damage description';
			}
			// this is the id of the form
			$.validator.addMethod(
				'email_or_phone_number',
				function (value, element) {
					var result = false;
					if (
						value.match(/^\(?[\+\(\)\d\s]{9,}$/) ||
						value.match(
							/^(([^-_<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						)
					) {
						result = true;
					}
					return result;
				},
				'Proszę podać prawidłowy email lub nr telefonu'
			);

			$('form:not(.form-contact)').validate({
				// $('form').validate({
				rules: {
					// name: {
					// 	required: true,
					// },
					email: {
						required: true,
					},
					message: {
						minlength: 5,
					},
				},
				messages: {
					name: {
						required: msg_valid_default_required,
					},
					policy: {
						required: msg_valid_nip_required,
					},
					email: {
						required: msg_valid_email_email,
						email: msg_valid_email_email,
					},
					phone: {
						required: msg_valid_phone,
					},
					message: {
						required: msg_valid_damage_description,
						minlength: jQuery.validator.format(
							'Proszę wpisać więcej niż {0} znaków.'
						),
					},
				},
				submitHandler: function (f) {
					console.log('button1');
					sendRequest(f);
				},
			});
			$('#form-contact').validate({
				// $('form').validate({
				rules: {
					// name: {
					// 	required: true,
					// },
					email: {
						email_or_phone_number: true,
						required: true,
					},
					message: {
						minlength: 5,
					},
				},
				messages: {
					name: {
						required: msg_valid_default_required,
					},
					email: {
						required: msg_valid_default_required,
						email: msg_valid_email_or_phone,
					},
					message: {
						required: msg_valid_default_required,
						minlength: jQuery.validator.format(
							'Proszę wpisać więcej niż {0} znaków.'
						),
					},
				},
				submitHandler: function (f) {
					console.log('button1');
					sendRequest(f);
				},
			});
		},

		//remove single letters
		//© https://codepen.io/nowapracownia/pen/JrPMEM
		removeOrphans: function removeOrphans() {
			$('p:not(.not-remove-orphans)').each(function () {
				var textReplace = $(this).html();
				var lettersToReplace = [
					'a',
					'i',
					'o',
					'u',
					'w',
					'z',
					'A',
					'I',
					'O',
					'U',
					'W',
					'Z',
				];
				var arrayLength = lettersToReplace.length;
				for (var i = 0; i < arrayLength; i++) {
					var textSplit = textReplace.split(
						' ' + lettersToReplace[i] + ' '
					);
					var textReplace = textSplit.join(
						' ' + lettersToReplace[i] + '&nbsp;'
					);
				}
				$(this).empty();
				$(this).html(textReplace);
			});
		},
	};

	$(function () {
		App.init();
	});
});
